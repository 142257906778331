@import "../util/index";

.form-floating {
  & .form-select {
    height: auto;
  }

  & .form-control {
    height: 43px;
    padding: 21px 0 7px 4px !important;
    border-left: 4px;
    border-bottom: 1px;
    border-bottom-color: colour(blacks, 200);
    border-right: 0;
    border-top: 0;
    border-style: solid;
    border-radius: 0;
    border-left-color: #fff;
    font-size: 12px;
    line-height: 14px;

    &.amount {
      padding-left: 12px !important;
    }

    &.is-invalid, &.is-invalid:focus {
      background-color: colour(reds, 100.7);
      border-color: colour(reds, 100);
      color: colour(common, text);
    }

    &:focus {
      background-color: white;
      border-color: colour(blues, 100);
      box-shadow: none;
    }

    &:disabled {
      background-color: colour(grays, 30);
      color: colour(common, text);
      border-bottom-color: colour(common, text);
      border-left-color: colour(grays, 30);
    }
  }

  &.form-control:focus, .form-control:not(:placeholder-shown) {
    padding-top: 2rem;
  }

  .amount + label {
    padding-left: 14px;
  }

  // Input - Resting state
  label {
    padding-left: 8px;
    font-size: 12.5px;
  }
}

// Input - Focus state
.form-floating > .form-control:focus ~ label, .form-floating > .form-control:not(:placeholder-shown) ~ label, .form-floating > .form-select ~ label {
  padding-top: 6px;
  font-size: 8px;
  transform: scale(1) translateY(-0.5rem) translateX(0.15rem);
  padding-left: 6px;
  border: 0;
  color: colour(common, text);
  opacity: 1;
}

.form-control-container {
  margin-bottom: 41px;
  position: relative;
}

.form-select-sm {
  padding-left: 8px;
  height: 31px;
  padding-top: 2px;
}

.form-select {
  color: colour(common, text);
}

label {
  color: colour(common, text);
  font-size: 12.5px;

  &.required {
    &:after {
      content: '*';
    }
  }
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  background-image: none;
  background-color: colour(reds, 100.7);
}

.error-message {
  position: absolute;
  top: -20px;
  display: block;
  width: 100%;
  background: #fff !important;
  color: colour(reds, 50);
  white-space: nowrap;
  font-size: 12.5px;
  line-height: 15px;
}

.special-input-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

.special-input {
  width: 220px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  position: relative;
  transition: 0.03s;

  mat-datepicker-toggle {
    position: absolute;
    right: 0;
    top: 13px;
  }

  &.error {
    input {
      padding-left: 10px;
      background-color: colour(reds, 100.7) !important;
      outline-color: colour(reds, 100) !important;
      border-color: colour(reds, 100) !important;
      border-left: 9px solid colour(reds, 100) !important;
    }

    mat-icon {
      top: 18px !important;;
    }
  }

  &.amount {
    margin-bottom: 60px;

    &:before {
      content: '$';
      font-size: 31px;
      font-weight: 500;
      position: absolute;
      bottom: 24px;
      left: 29px;
    }

    input {
      padding: 15px 0 15px 46px;

      &:focus {
        padding-left: 38px;
      }
    }
  }

  &.date {
    input {
      padding-right: 0;

      &::placeholder {
        font-size: 24px;
      }
    }
  }

  input {
    padding: 15px 25px 15px 18px;
    border-radius: 7px !important;
    border: 1px solid colour(grays, 50);

    &:focus {
      outline-color: colour(blues, 100);
      border-color: colour(blues, 100);
      border-left: 9px solid colour(blues, 100);
      padding-left: 10px;
    }

    &:disabled {
      background-color: colour(grays, 50);
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: colour(input, placeholder);
    }

    &::-moz-placeholder { /* Firefox 19+ */
      color: colour(input, placeholder);
    }

    &:-ms-input-placeholder { /* IE 10+ */
      color: colour(input, placeholder);
    }

    &:-moz-placeholder { /* Firefox 18- */
      color: colour(input, placeholder);
    }
  }

  span, input {
    font-weight: 500;
    font-size: 31px;
    line-height: 36px;
    color: colour(blacks, 200);
  }

  .form-control:focus {
    box-shadow: 0 0 0 0 colour(blues, 100) !important;
    -webkit-box-shadow: 0 0 0 0 colour(blues, 100) !important;
    -moz-box-shadow: 0 0 0 0 colour(blues, 100) !important;
    border-color: colour(blues, 100) !important;
  }

  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    box-shadow: 0 0 0 0 colour(reds, 100) !important;
    -webkit-box-shadow: 0 0 0 0 colour(reds, 100) !important;
    -moz-box-shadow: 0 0 0 0 colour(reds, 100) !important;
    border-color: colour(reds, 100) !important;
  }
}
