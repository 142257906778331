// @use '@angular/material' as mat;
@use '@angular/material' as mat;

$md-cc-primary: (
    50 : #e7e7ed,
    100 : #c2c2d2,
    200 : #9a9ab4,
    300 : #727296,
    400 : #535380,
    500 : #353569,
    600 : #303061,
    700 : #282856,
    800 : #22224c,
    900 : #16163b,
    A100 : #7a7aff,
    A200 : #4747ff,
    A400 : #1414ff,
    A700 : #0000fa,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-cc-accent: (
    50 : #ffede7,
    100 : #ffd3c3,
    200 : #ffb69b,
    300 : #ff9972,
    400 : #ff8354,
    500 : #ff6d36,
    600 : #ff6530,
    700 : #ff5a29,
    800 : #ff5022,
    900 : #ff3e16,
    A100 : #ffffff,
    A200 : #fffcfc,
    A400 : #ffd0c9,
    A700 : #ffbbaf,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$my-primary: mat.define-palette($md-cc-primary, 500);
$my-accent: mat.define-palette($md-cc-accent, 600, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
// $my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
//    warn: $my-warn,
 )
));
@include mat.all-component-themes($my-theme);
// $primary: mat-palette($md-gistam-primary);
// $accent: mat-palette($md-gistam-accent);
// $theme: mat-light-theme($primary, $accent);
// @include angular-material-theme($theme);