@import "../util/index";

.custom-hide {
  display: none;
}

.page-view {
  margin: 0 18px 0 65px;
  min-height: 72vh;

  @include bp-from(xl) {
    margin: 0 47px 0 65px;
    min-width: 768px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    h1, h2, h3 {
      margin-bottom: 0;
    }
  }

  &__description {
    font-size: 14px;
    line-height: 20px;
  }

  &__loading {
    box-shadow: 0px 0px 8px colour('common', 'box-shadow');
    border-radius: 19px;
    border: none;
    margin-bottom: 30px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 60px;

    button:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}

.search {
  box-shadow: inset 0px 0px 2.24633px colour('common', 'box-shadow');
  border-radius: 11.6615px;
  display: flex;
  align-items: center;
  color: colour('common', 'text');
  height: 27.53px;
  padding: 7.06px 14px 8.11px 10.06px;

  input {
    border: 0;
    font-size: 12.5px;
    line-height: 15px;
    margin-left: 0.25rem;
    margin-top: 1px;

    &::placeholder {
      color: colour(input, placeholder);
    }
  }

  mat-icon {
    width: 20px;
  }
}

.table-container {
  box-shadow: 0px 0px 8px colour('common', 'box-shadow');
  background-color: white;
  border-radius: 19px;
  border: none;
  padding: 0 24px;
  color: colour('common', 'text');
  min-height: 736px;
  position: relative;

  @include bp-from(xl) {
    padding: 0 24px;
  }

  .footer {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #E0E0E0;
    width: calc(100% - 90px);
    height: 60px;
  }

  tr {
    th {
      height: 60px;
      min-width: 82px;
      max-width: 473px;
      letter-spacing: 0px;
    }

    th:first-child, td:first-child {
      padding-left: 0;
    }

    th:last-child, td:last-child {
      padding-right: 0;
    }
  }

  td {
    border-bottom: 0;
    height: 100%;
    vertical-align: middle;
    display: table-cell;
    font-size: 12.5px;
    color: colour(common, text);

    &.no-data-row {
      height: 77px;

      .heading {
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 12px;
        margin-top: 34px;
      }
    }

    .align-right {
      display: flex;
      justify-content: flex-end;
      margin: 7px 0;
    }

    button {
      margin-bottom: 0;
    }
  }

  thead th {
    font-weight: 500;
    font-size: 12.5px;
    line-height: 15px;
    padding-bottom: 12px;
    height: 60px;
    vertical-align: bottom;
  }

  tr.mat-header-row {
    height: auto;
  }

  tr.mat-row, tr.mat-footer-row {
    height: 77px;
  }

  td.mat-cell, td.mat-footer-cell {
    border: 0;
  }

  td.text-center {
    text-align: center;
  }
}

.breadcrumbs {
  margin-top: 0;
  margin-bottom: 23px;
  min-height: 100%;

  color: colour('breadcrumbs', 'text');

  a {
    color: colour('common', 'text')
  }

  span {
    margin-left: 0.5rem;
  }
}

.section.procedure {
  text-align: left;
  padding-bottom: 9.28px;
  margin-bottom: 20px;

  .item {
    display: flex;
    margin-bottom: 65px;
    align-items: center;

    .label, .value {
      flex: 50%;
    }

    &.highlight {
      background-color: colour(blues, 100);
    }
  }

  .label {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;

    .description {
      margin-top: 8px;
      font-size: 12.5px;
      line-height: 15px;
      font-weight: normal;
    }
  }

  .value {
    font-weight: 500;
    font-size: 25.92px;
    line-height: 34px;

  }
}

.section.patient {
  font-size: 18px;
  line-height: 20px;
  text-align: left;
  border-bottom: 1px solid colour(grays, 30);
  padding-bottom: 9.28px;
  margin-bottom: 20px;

  .label {
    font-weight: 500;
    margin-bottom: 10px;
  }

  .value {
    margin-bottom: 25px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
