@import "../util";

.powerpay {
  .header {
    background-color: colour(header, background, 'none', $custom-colors) !important;
    border-bottom: 1px solid colour(header, border, 'none', $custom-colors);
  }

  .side-nav-list {
    .mat-list-item.active {
      border-color: colour(sidenav, border, 'none', $custom-colors) !important;
    }
  }

  .button {
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    font-weight: 700;
    font-size: 14px;

    &:hover:not(:disabled), &:focus:not(:disabled) {
      background-color: transparent;
      color: colour(inputs, color, 'none', $custom-colors);
      border: 1px solid colour(inputs, color, 'none', $custom-colors);
    }

    &-primary {
      background-color: colour(inputs, color, 'none', $custom-colors);
      color: white;
      border: 1px solid colour(inputs, color, 'none', $custom-colors);
    }

    &-secondary {
      background-color: transparent;
      color: colour(inputs, color, 'none', $custom-colors);
      border: 1px solid colour(inputs, color, 'none', $custom-colors);
    }

    &-link {
      color: colour(inputs, color, 'none', $custom-colors)
    }
  }

  .mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
    background-color: colour(inputs, color, 'none', $custom-colors);
  }

  .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
    border-color: colour(inputs, color, 'none', $custom-colors);
  }

  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: colour(inputs, color, 'none', $custom-colors);
  }

  .app-type-container .mat-radio-button {
    margin-bottom: 35.52px !important;
  }

  .mat-radio-label-content {
    font-family: 'Lato', sans-serif;
    letter-spacing: 0;
    font-weight: 500;
    font-size: 14px;
  }
}
