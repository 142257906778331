@import "../util";

$custom-colors: (
  header: (
    background: #ebf9ff
  )
);

.ccare {
  .header {
    background-color: colour(header, background, 'none', $custom-colors) !important;
    border-bottom: 0;
  }

  .button {
  }
}
