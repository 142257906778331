@import "../util/index";

h1, h2, h3, h4, h5, h6 {
  color: colour(common, text) !important;
  font-weight: 500 !important;
}

h1 {
  font-size: 44.79px !important;
  line-height: 54px !important;
}

h2 {
  font-size: 31.1px !important;
  line-height: 41px !important;
}

h3 {
  font-size: 25.92px !important;
  line-height: 34px !important;
}

.page-view {
  .application {
    h2 {
      margin-bottom: 0.5rem;
      margin-top: 17px !important;
    }
  }
}
