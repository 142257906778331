$breakpoints: (
  xs: 0,
  s: 576px,
  m: 768px,
  l: 992px,
  xl: 1024px,
  xxl: 1200px
);

/**
 * All breakpoints from a given one up.
 * @param {string} $name
 */
@mixin bp-from($name) {
  $width: map-get($breakpoints, $name);
  @media (min-width: $width) {
    @content;
  }
}

/**
 * All breakpoints up to a given one.
 * @param {string} $name
 */
@mixin bp-to($name) {
  $width: map-get($breakpoints, $name) - 1;
  @media (max-width: $width) {
    @content;
  }
}

/**
 * All breakpoints from a given name up to a second name.
 * @param {string} $start
 * @param {string} end
 */
@mixin bp-between($start, $end) {
  $from: map-get($breakpoints, $start);
  $to: map-get($breakpoints, $end) - 1;
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}

/**
 * Target a specific breakpoint.
 * @param {string} $name
 */
@mixin bp-only($name) {
  $from: map-get($breakpoints, $name);
  $to: nextBreakpointValue($name) - 1;
  @media (min-width: $from) and (max-width: $to) {
    @content;
  }
}
