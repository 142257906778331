.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  max-width: 290px;
  width: 100%;
  border: 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 12.5px;
  line-height: 15px;
  white-space: nowrap;
  letter-spacing: 0;
  height: 52px;
  margin-bottom: 30px;
  transition: 0.3s;

  &-medium {
    width: auto;
    height: 31px;
    padding: 8px 16px;
  }

  &:hover:not(:disabled), &:focus:not(:disabled) {
    background-color: colour('button', 'hover', 'background');
    color: colour('button', 'hover', 'text');
    border: 1px solid colour('button', 'hover', 'border');
  }

  &:disabled {
    opacity: 0.23;
    pointer-events: none;
  }

  &-primary {
    background-color: colour('button', 'primary', 'background');
    color: colour('button', 'primary', 'text');
    border: 1px solid colour('button', 'primary', 'border');
  }

  &-secondary {
    background-color: colour('button', 'secondary', 'background');
    color: colour('button', 'secondary', 'text');
    border: 1px solid colour('button', 'secondary', 'border');
  }

  &-link {
    border: 0;
    background-color: transparent;
    color: colour('button', 'link', 'text')
  }

  &-link {
    padding: 0;
    font-size: 16px;
    line-height: 1;
  }
}
