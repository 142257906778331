@import "../util";

.page-modal-content {
  padding: 0 34px;
  text-align: center;

  h2 {
    margin-bottom: 8px;
  }

  .description {
    font-size: 12.5px;
    line-height: 15px;
    color: colour(common, text);
    max-width: 300px;
    margin: 0 auto;
  }

  .actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button:last-child {
      margin-bottom: 0;
    }
  }

  mat-icon.error-icon {
    margin: 36px 0 55px 0;
    display: inline-block;
    width: 104.8px;
    height: 105.8px;
  }

  fa-icon {
    margin: 40px 0 60px 0;
    display: block;

    &.success svg {
      font-size: 112px;
    }

    &.success svg path {
      color: colour('svg', 'success');
    }
  }

  .error-banner {
    margin-top: 32px !important;
    margin-bottom: 29px !important;
    margin-left: -38px !important;

    p {
      width: calc(100% - 65px);
    }
  }

  .search {
    display: inline-flex;
    margin-bottom: 16.47px;
    text-align: left;
  }

  .table-container {
    box-shadow: none;
    padding: 0;
    min-height: 400px;
    text-align: left;
    margin-bottom: 37.27px;

    tr.mat-row, tr.mat-footer-row {
      height: 60px;
    }

    th, td {
      min-width: 100px;
    }

    .footer {
      width: 100%;
    }
  }

  .search-container {
    text-align: right;
    margin-top: 32px;
  }
}
