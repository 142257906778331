@import "../util/index";

mat-drawer-container {
  height: 100%;
}

mat-drawer {
  background-color: colour(grays, 0) !important;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.15) !important;
}

.mat-list-base {
  padding-top: 0 !important;
}

.mat-list-item-content {
  justify-content: center;
}

.modal-open {
  .mat-drawer {
    top: 81px;
    z-index: 999;
  }

  .mat-drawer-container {
    position: static;
  }
}

.mat-drawer-content {
  background-color: white;
}

.mat-header-cell {
  color: colour('common', 'text') !important;
}

.mat-sort-header-content {
  text-align: left !important;
}

mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-color: colour('common', 'table-border-color');
}

.mat-form-field-label-wrapper mat-label {
  color: colour('common', 'text');
  margin-top: -0.125;
  display: block;
}

.mat-form-field-label {
  top: 0.8em;
}

.cdk-overlay-dark-backdrop {
  background: transparent;
}

.modal-open .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0) 82px,
      colour('overlay', 'background') 82px,
      colour('overlay', 'background') 100%
  );
}

.cdk-overlay-backdrop.mat-overlay-transparent-backdrop {
  opacity: 0 !important;
}

.mat-dialog-container {
  padding: 58px 0 50px 0 !important;
  text-align: center;
  border-radius: 19px !important;
  box-shadow: none;
}

.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  height: 62px !important;
}

.mat-nav-list {
  a {
    display: flex;
    align-items: center;
  }
}

.mat-input-element, .mat-drawer-container {
  caret-color: colour(common, text);
  color: colour(common, text);
}

.mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: colour(input, radio, background);
}

.mat-radio-outer-circle:not(.mat-radio-checked) {
  border-color: colour(input, radio, inactive);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: colour(blues, 200);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: colour(input, radio, background);
}

.mat-checkbox-frame {
  border-color: colour(input, radio, inactive);
}

.form-control {
  color: colour(common, text);
}

.app-dialog {
  width: 605px !important;
  max-width: 605px !important;
}


.mat-form-field {
  width: 100%;
}

.mat-form-field-flex {
  padding-left: 8px;
  color: colour(common, text);
}

.mat-form-field.select {
  &.required {
    mat-label:after {
      content: '*';
    }
  }

  &.mat-form-field-invalid {
    .mat-form-field-flex {
      border-left: 4px;
      border-right: 0;
      border-bottom: 0;
      border-top: 0;
      border-color: colour(reds, 100);
      border-style: solid;
      background-color: colour(reds, 100.7);
    }

    .mat-form-field-underline, .mat-form-field-ripple {
      background-color: colour(reds, 100);
    }
  }

  &.mat-focused {
    .mat-form-field-flex {
      border-left: 4px;
      border-right: 0;
      border-bottom: 0;
      border-top: 0;
      border-color: colour(blues, 100) !important;
      border-style: solid;
    }

    .mat-form-field-underline, .mat-form-field-ripple {
      background-color: colour(blues, 100) !important;
    }
  }
}

.mat-select-arrow {
  margin: 4px 8px !important;
  border: 0 !important;
  background: url('/assets/img/icons/chevron-right.svg') no-repeat right;
  width: 10px !important;
  height: 10px !important;
  transform: rotate(90deg);
}

.mat-form-field {
  font-size: 12px;
  line-height: 14px;
}

.mat-form-field-hide-placeholder .mat-select-placeholder, .mat-select-value {
  color: colour(common, text);
  vertical-align: middle;
}

.mat-form-field-appearance-legacy .mat-form-field-ripple, .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: colour(common, text);
  height: 1px;
}

.mat-option {
  color: colour(common, text);
}

.mat-select-panel-wrap, .mat-autocomplete-panel-wrap {
  position: relative;
}

.mat-select-panel {
  min-width: calc(100% + 8px) !important;
  width: 100% !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px 0px 10px 10px !important;
  position: absolute;
  top: 25px;
  left: 8px;
}

.mat-autocomplete-panel {
  min-width: calc(100% + 8px) !important;
  width: 100% !important;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25) !important;
  border-radius: 0px 0px 10px 10px !important;

  .mat-option {
    font-size: 12.5px;
    line-height: 15px;
    height: 45px !important;
  }
}

.mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option, .mat-autocomplete-panel .mat-optgroup-label, .mat-autocomplete-panel .mat-option {
  padding-left: 8px;
  height: 45px !important;
  line-height: 45px;
}

.mat-option.mat-active, .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple), .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background-color: colour(blues, 50);
  color: colour(common, text);
  font-size: 15px !important;
  line-height: 18px !important;
}

.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background-color: colour(grays, 30);
  color: colour(common, text);
  font-size: 15px !important;
  line-height: 18px !important;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 16.5px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #2A2A2A;
}

.mat-select-trigger {
  position: relative;
  top: 3px;
}

.dropdown-panel {
  &.state {
    width: 92px !important;
    min-width: 100px !important;
  }
}

.mat-form-field-label-wrapper mat-label {
  position: relative;
  top: 4.7px;
}

.mat-form-field-type-mat-select .mat-form-field-label {
  height: 25px;
}

.mat-drawer-inner-container {
  overflow: hidden !important;
}

.mat-icon-button[aria-expanded="true"] {
  svg path.outline {
    fill: colour(common, background);
  }
}

.mat-menu-panel {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.11);
  border-radius: 19px !important;
}

.mat-menu-content:not(:empty) {
  padding: 0 !important;
}

.mat-menu-item {
  color: colour(common, text);
  height: 61px !important;
  font-size: 12.5px;
  line-height: 15px;

  &:hover {
    background-color: colour(blues, 100) !important;
    font-weight: 500;
  }
}

.mat-paginator-page-size {
  display: none !important;
}

.mat-paginator-range-label {
  margin-top: 4px !important;
  font-size: 12.5px;
  line-height: 15px;
  color: colour(common, text);
}

.mat-paginator-navigation-previous:not(.mat-button-disabled), .mat-paginator-navigation-next:not(.mat-button-disabled) {
  fill: colour(common, text);
}

.mat-datepicker-content {
  box-sizing: border-box;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 11%);
  border-radius: 12px;
}

.mat-calendar-body-cell-content, .mat-date-range-input-separator {
  color: colour(common, text);
  font-size: 12.5px;
  line-height: 15px;
  text-decoration: none;
}

.mat-calendar-body-selected {
  background-color: colour(blues, 100);
  font-weight: 500;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: colour(common, inactive);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: colour(common, text);
}

.mat-calendar-table-header {
  color: colour(grays, 50);
  text-decoration: none;

  th {
    padding-bottom: 0 !important;
  }

  tr:not(:first-child) {
    display: none;
  }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: colour(grays, 40);
}

.mat-calendar-table-header-divider {
  display: none;
}

abbr.mat-calendar-abbr {
  font-size: 12.5px;
  line-height: 15px;
  text-decoration: none !important;
}

abbr.mat-calendar-abbr[title='Sunday']:after {
  content: 'un';
}

abbr.mat-calendar-abbr[title='Monday']:after {
  content: 'on';
}

abbr.mat-calendar-abbr[title='Tuesday']:after {
  content: 'ues';
}

abbr.mat-calendar-abbr[title='Wednesday']:after {
  content: 'ed';
}

abbr.mat-calendar-abbr[title='Thursday']:after {
  content: 'hur';
}

abbr.mat-calendar-abbr[title='Friday']:after {
  content: 'ri';
}

abbr.mat-calendar-abbr[title='Saturday']:after {
  content: 'at';
}

abbr.mat-calendar-abbr[title='Sunday']:after {
  content: 'un';
}
