.mat-btn-sm {
    padding: 0 6px !important;
    font-size: 12px !important;
    line-height: 25px !important;
  }
  
  .btn-main{
    color: #fff;
    background-color: #353569;
    border-color: #353569;
    border-radius: 24px !important;
    text-transform: uppercase;
  }
  
  .btn-brand{
    border-radius: 25px !important;
    text-transform: uppercase;
    padding: 0 12px !important;
    font-size: 12px !important;
    line-height: 29px !important;
  }
  
  .btn-warn {
    color: #fff;
    background-color: #ff6d36;
    border-color: #ff6d36;
    border-radius: 12px;
  }
  
  .btn-cancel{
    color: rgb(22, 21, 21);
    background-color: #ffffff;
    border-color: #e5e5eb;
    border-radius: 24px;
    text-transform: uppercase;
  }
  
  .btn-secondary{
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    border-radius: 25px;
    padding: 0 12px !important;
    font-size: 12px !important;
    line-height: 29px !important;
  }